import React from 'react';
import ReactDOM from 'react-dom';
import Recommender from '../components/Recommender';
import CodeContainer from '../components/CodeContainer';
import { decodeHTML } from "../components/utils/dom";

const components = {
  recommender: 'Recommender',
  codeContainer: 'CodeContainer',
  profileForm: 'ProfileForm',
};

/**
 * Initializes the recommender component.
 *
 * @param {HTMLElement} rootElement
 *   HTML container.
 * @param {Object} options
 *   Props.
 * @param {String} options.title
 *   Component title.
 * @param {String} options.headerAlignment
 *   Header alignment (left | center). Left by default.
 * @param {String} options.layout
 *   Layout (3-cols | 4-cols). Default: 4-cols
 * @param {Array} options.recommendations
 *   Recommendations list.
 * @param {Boolean} options.overflow
 *   Overflow mode. Default - TRUE.
 * @param {Boolean} options.showGreenElement
 *   Green element flag. Default - FALSE.
 */
const initializeRecommenderComponent = (rootElement, options) => {
  rootElement.innerHTML = '';
  const defaultOptions = {
    title: '',
    headerAlignment: 'left',
    layout: '4-cols',
    recommendations: [],
    overflow: true,
    showGreenElement: false,
  };
  const props = {...defaultOptions, ...options}
  try {
    ReactDOM.render(<Recommender {...props}/>, rootElement);
  } catch (e) {
    console.warn('Failed to mount Recommender component.');
  }
};

const initializeCodeContainerComponent = (rootElement, options) => {
  const reactProps = rootElement.dataset?.reactProps ? JSON.parse(rootElement.dataset.reactProps) : {};
  const html = decodeHTML(rootElement.querySelector('pre').innerHTML);

  const defaultOptions = {
    childrenContent: html,
  };

  const props = {...defaultOptions, ...options, ...reactProps}
  ReactDOM.render(<CodeContainer {...props}/>, rootElement);
};

const initializeProfileFormComponent = (rootElement, options) => {
  const initEvent = new CustomEvent('profile-form.init', {
    detail: {
      rootElement: rootElement,
      params: options,
    },
  });

  document.dispatchEvent(initEvent);
};

/**
 * Component initializer.
 *
 * @param {Object} config
 *   Config.
 * @param {String} config.target
 *   Target element selector.
 * @param {String} config.component
 *   Component ID.
 * @param {Object} config.options
 *   Components props.
 */
window.initializeComponent = function (config) {
  const {target, targetElement, component, options = {}} = config;
  if ((!target && !targetElement) || !component) {
    return;
  }

  let rootElement;
  if (targetElement) {
    rootElement = targetElement;
  } else {
    rootElement = document.querySelector(target);
  }

  if (!rootElement) {
    console.warn(`Unable to initialize component; Element "${target}" not found.`);
    return;
  }

  switch (component) {
    case components.recommender:
      initializeRecommenderComponent(rootElement, options);
      break;
    case components.codeContainer:
      initializeCodeContainerComponent(rootElement, options);
      break;
    case components.profileForm:
      initializeProfileFormComponent(rootElement, options);
      break;
  }
}
