// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import {I18n} from "i18n-js";
import translations from "./i18n/translations.json";
import Cookies from "js-cookie";
import * as tracing from "./tracing";
import {register} from "./nv-namespace";

Rails.start();
ActiveStorage.start();

register('tracing', tracing);

import "./utils"
import "./nv-country-code"
import "./ls_track"
import "./social-share-button"
import "./eula"
import "./nv-react-components"
import LocalTime from "local-time"
LocalTime.start()

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// translation logic
const i18n = new I18n(translations);
i18n.missingBehavior = 'guess'
i18n.defaultLocale = 'en';
i18n.enableFallback = true;
i18n.locale = document.documentElement.lang;
window.I18n = i18n;
window.Cookies = Cookies;
